import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"

const Error = () => {
    return (
        <Layout>
            <main className="error__page u-bg-grey">
                <div>
                    <h2>Whoops! This page doesn't exist.</h2>
                    <Link to="/" className="btn">
                        back home
                    </Link>
                </div>
            </main>
        </Layout>
    )
}

export default Error
